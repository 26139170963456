import React from 'react';
import { Container, Button, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';

function Home() {

  const { version } = useAppContext()

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography
          sx={{
            fontWeight: 600,
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          同志社起業部Web
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to="/register"
          sx={{
            fontWeight: 600,
            fontSize: 20,
            mt: 2,
            borderRadius: 10,
            px: 10,
          }}
        >
          新規登録
        </Button>
        <Button
          variant="contained"
          component={Link}
          to="/login"
          sx={{
            fontWeight: 600,
            fontSize: 20,
            mt: 2,
            px: 10,
            borderRadius: 10,
            backgroundColor: '#32cd32',
            ':hover': {
              backgroundColor: '#009933',
            },
          }}
        >
          ログイン
        </Button>

        <Box mt={4} display="flex" justifyContent="center">
          <Button
            component={Link}
            to="/terms"
            sx={{
              fontWeight: 600,
              fontSize: 14,
              color: '#555',
              textDecoration: 'underline',
            }}
          >
            利用規約
          </Button>
          <Button
            component={Link}
            to="/privacy-policy"
            sx={{
              fontWeight: 600,
              fontSize: 14,
              color: '#555',
              textDecoration: 'underline',
              ml: 2, // ボタンの間にマージンを追加
            }}
          >
            プライバシーポリシー
          </Button>
        </Box>


        <Typography
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
            fontSize: 12,
            color: '#888',
          }}
        >
          v {version}
        </Typography>
      </Box>
    </Container>
  );
}

export default Home;