import React from 'react';
import { Box, Typography, List, ListItem, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';

const PlaceList = () => {
    const { places } = useAppContext();
    const navigate = useNavigate();

    const handlePlaceClick = (placeId) => {
        navigate('/add-event', { state: { placeId } }); // イベント追加画面に場所のIDを渡して遷移
    };

    return (
        <Box p={4}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold' }}>
                場所一覧
            </Typography>
            <List>
                {places.map((place) => (
                    <Box key={place.id} sx={{ mb: 2, borderRadius: 3, background: '#dcdcdc' }}>
                        <ListItem button onClick={() => handlePlaceClick(place.id)}>
                            <Box>
                                <Typography sx={{ fontWeight: 600, fontSize: 20 }}>
                                    {place.name}
                                </Typography>
                                <Typography sx={{ color: '#555', fontSize: 16 }}>
                                    {place.address} ({place.postalCode})
                                </Typography>
                            </Box>
                        </ListItem>
                        <Divider />
                    </Box>
                ))}
            </List>
        </Box>
    );
};

export default PlaceList;