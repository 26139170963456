import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { useAppContext } from '../contexts/AppContext';
const functions = getFunctions(undefined, 'asia-northeast2');

function isDebug() {
    return window.location.hostname === 'localhost';
}

function ApprovalPending() {
    const auth = getAuth();
    const user = auth.currentUser;
    const { userData } = useAppContext()  // userData を取得

    const [error, setError] = useState('');

    useEffect(() => {
        const sendRequest = async () => {
            if (!user) {
                setError('ユーザーが認証されていません。');
                return;
            }

            if (isDebug()) {
                console.log('デバッグ')
                return;
            }

            const sendParticipationRequest = httpsCallable(functions, 'sendParticipationRequest');

            try {
                const userName = userData.name;
                await sendParticipationRequest({ name: userName });
            } catch (error) {
                console.error("参加リクエストの送信中にエラーが発生しました:", error);
                setError('参加リクエストの送信に失敗しました。');
            }
        };

        if (user && userData.name) {
            sendRequest();
        }
    }, [user, userData.name]);  // 依存関係配列にuserとuserData.nameを追加

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError('');
            }, 60000); // 1分後にエラーメッセージを消去

            return () => clearTimeout(timer); // クリーンアップタイマー
        }
    }, [error]);

    return (
        <Container maxWidth="sm">
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                textAlign="center"
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        pb: 5,
                        whiteSpace: 'nowrap', // 改行を防ぐ
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    variant="h6"
                    gutterBottom
                >
                    アカウント登録が完了しました。
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        pb: 6,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    variant="body1"
                    component="p"
                >
                    管理者が参加承認した後に再度ログインしてください
                </Typography>

                {error && <Alert severity="error" sx={{
                    borderRadius: 10,
                    mb: 2,
                    width: '100%',
                    mt: 2
                }}>
                    {error}
                </Alert>}

                <Button
                    component={Link}
                    variant='text'
                    to="/"
                    sx={{
                        borderRadius: 20,
                    }}
                >
                    ホームに戻る
                </Button>
            </Box>
        </Container>
    );
}

export default ApprovalPending;