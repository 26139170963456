import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress, Alert } from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../config/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';
import CryptoJS from 'crypto-js';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const { setUserData } = useAppContext()

  function extractDomain(email) {
    const domainLength = 14; 
    return email.slice(-domainLength);
  }

  // ドメインをハッシュ化する関数
  function hashDomain(domain) {
    const hash = CryptoJS.SHA512(domain);
    return hash.toString(CryptoJS.enc.Hex);
  }

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 60000); // 1分後にエラーメッセージを消去

      return () => clearTimeout(timer); // クリーンアップタイマー
    }
  }, [error]);

  useEffect(() => {
    const isEmailValid = email.includes('@');
    const isPasswordNotEmpty = password.trim() !== '';
    setIsButtonDisabled(!isEmailValid || !isPasswordNotEmpty);
  }, [email, password]);

  const handleRegister = async () => {
    setLoading(true);
    setError('');

    const emailDomain = extractDomain(email);
    const hashedDomain = hashDomain(emailDomain);
    if (hashedDomain !== process.env.REACT_APP_ALLOWED_DOMAIN_HASH) {
    
      setError('同志社大学のメールアドレスを入力してください');
      setLoading(false);
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const email = user.email;
        setUserData(prevState => ({
          ...prevState,
          uid,
          email,
          isEmailValid: false
        }));
      }
      navigate('/email-verification');
    } catch (error) {
      setError(`エラーが発生しました。もう一度お試しください`);
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography
          sx={{
            fontWeight: 600,
            pb: 5,
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          新規登録
        </Typography>
        {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
        <TextField
          label="メールアドレス"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
          sx={{ mt: 2 }}
        />
        <TextField
          label="パスワード"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="new-password"
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          onClick={handleRegister}
          disabled={isButtonDisabled || loading}
          sx={{
            fontWeight: 600,
            fontSize: 20,
            mt: 2,
            borderRadius: 10,
            height: 48,
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...(isButtonDisabled || loading) && {
              color: 'gray',
              backgroundColor: 'lightgray',
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : '登録'}
        </Button>
      </Box>
    </Container>
  );
}

export default Register;