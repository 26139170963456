import {  httpsCallable } from 'firebase/functions';
import { functions } from './firebaseConfig'; // Firebaseの初期化ファイルをインポート


// ユーザーを承認する関数
export async function approveUser(uid) {
    try {
        const data = { userid: uid };
        const approveUserFunction = httpsCallable(functions, 'approveUser');
        await approveUserFunction(data);
        return null;
    } catch (error) {
        return error;
    }
}

// ユーザーを拒否する関数
export async function rejectUser(uid) {
    try {
        const data = { userid: uid };
        const rejectUserFunction = httpsCallable(functions, 'rejectUser');
        await rejectUserFunction(data);
        return null;
    } catch (error) {
        return error;
    }
}

// ユーザーを退出させる関数
export async function exitUser(uid) {
    try {
        const data = { userid: uid };
        const exitUserFunction = httpsCallable(functions, 'exitUser');
        await exitUserFunction(data);
        return null;
    } catch (error) {
        return error;
    }
}