import React, { useState } from 'react';
import { Box, Typography, Button, List, ListItem, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebaseConfig';
import { signOut } from 'firebase/auth';
import { useAppContext } from '../contexts/AppContext';
import PasswordReset from './PasswordReset';
import ProfileEdit from './ProfileEdit';
import MfaPage from './MfaRootPage';
import AccountLink from './AccountLink';

const MyPage = () => {
  const navigate = useNavigate();
  const { userData, isMobile } = useAppContext();
  const [selectedSetting, setSelectedSetting] = useState('');

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate('/home');
      })
      .catch((error) => {
        console.error('Sign out error:', error);
      });
  };

  const handleSettingChange = (setting) => {
    if (isMobile) {
      // モバイルの場合はパスで遷移
      navigate(`/mypage/${setting}`);
    } else {
      // デスクトップの場合は従来の方法で右画面に表示
      setSelectedSetting(setting);
    }
  };

  const renderSettingDetail = () => {
    switch (selectedSetting) {
      case 'profile':
        return <ProfileEdit />;
      case 'link':
        return <AccountLink />;
      case 'mfa':
        return <MfaPage />;
      case 'password':
        return <PasswordReset />;
      default:
        return null;
    }
  };

  return (
    <Box display="flex" height="100vh" overflow="hidden">
      <Box
        width={isMobile ? '100%' : '470px'}
        p={3}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          overflowY: 'auto', // スクロールを可能にする
          height: '100vh', // 画面全体の高さ
        }}
      >
        <Box>
          <Box mb={4}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{userData.name}</Typography>
            <Box mt={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>メールアドレス</Typography>
              <Typography variant="h6" sx={{ color: '#666' }}>{userData.email}</Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>ユーザーID</Typography>
              <Typography variant="h6" sx={{ color: '#666' }}>{userData.uid}</Typography>
            </Box>
          </Box>
        
          <List component="nav">
            <ListItem
              button
              onClick={() => handleSettingChange('profile')}
              sx={{
                padding: 2,
                mb: 2,
                backgroundColor: selectedSetting === 'profile' && !isMobile ? '#e0e0e0' : '#f5f5f5',
                color: '#000',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#e0e0e0',
                },
              }}
            >
              <Typography variant="body1">プロフィール</Typography>
            </ListItem>
            {/* <ListItem
              button
              onClick={() => handleSettingChange('link')}
              sx={{
                padding: 2,
                mb: 2,
                backgroundColor: selectedSetting === 'link' && !isMobile ? '#e0e0e0' : '#f5f5f5',
                color: '#000',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#e0e0e0',
                },
              }}
            >
              <Typography variant="body1">アカウント連携</Typography>
            </ListItem>
            <ListItem
              button
              onClick={() => handleSettingChange('mfa')}
              sx={{
                padding: 2,
                mb: 2,
                backgroundColor: selectedSetting === 'mfa' && !isMobile ? '#e0e0e0' : '#f5f5f5',
                color: '#000',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#e0e0e0',
                },
              }}
            >
              <Typography variant="body1">2段階認証</Typography>
            </ListItem> */}
            <ListItem
              button
              onClick={() => handleSettingChange('password')}
              sx={{
                padding: 2,
                mb: 2,
                backgroundColor: selectedSetting === 'password' && !isMobile ? '#e0e0e0' : '#f5f5f5',
                color: '#000',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#e0e0e0',
                },
              }}
            >
              <Typography variant="body1">パスワードを変更する</Typography>
            </ListItem>
            <Divider sx={{ my: 2, bgcolor: '#ddd' }} />
            <Button
              variant="contained"
              fullWidth
              onClick={handleSignOut}
              sx={{
                padding: 2,
                fontWeight: 700,
                fontSize: 18,
                textTransform: 'none',
                backgroundColor: '#e74c3c',
                color: '#fff',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#c0392b',
                },
              }}
            >
              ログアウト
            </Button>
          </List>
        </Box>
      </Box>

      {/* 右サイドの詳細表示はモバイルの場合には表示しない */}
      {!isMobile && (
        <Box
          width="75%"
          p={4}
          sx={{
            overflowY: 'auto', // 右側もスクロールを許可
            height: '100vh',
          }}
        >
          {renderSettingDetail()}
        </Box>
      )}
    </Box>
  );
};

export default MyPage;