import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress, Alert } from '@mui/material';
import { auth, db } from '../config/firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';
import {  updateProfile} from 'firebase/auth';

function UpdateName() {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const { setUserData, userData } = useAppContext()

  const handleNameChange = (e) => {
    setName(e.target.value);
    setIsButtonDisabled(e.target.value.trim() === '');
  };

  const handleUpdateName = async () => {
    setLoading(true);
    setError('');
    try {
      const userRef = doc(db, 'Users', userData.uid);

      await updateDoc(userRef, { name });
      await updateProfile(auth.currentUser,{
        displayName: name
      });
      setUserData(prevState => ({
        ...prevState,
        name,
      }));
      navigate('/approval-pending');
    } catch (error) {
      setError('名前の更新に失敗しました。' + error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography
          sx={{
            fontWeight: 600,
            // pb: 5,
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          ニックネームを入力
        </Typography>
       
        {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
        <TextField
          label="ニックネームを入力..."
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={handleNameChange}
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          onClick={handleUpdateName}
          disabled={isButtonDisabled || loading}
          sx={{
            fontWeight: 600,
            fontSize: 20,
            mt: 2,
            borderRadius: 10,
            height: 46,
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...(isButtonDisabled || loading) && {
              color: 'gray',
              backgroundColor: 'lightgray',
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : '次へ'}
        </Button>
      </Box>
    </Container>
  );
}

export default UpdateName;