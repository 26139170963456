import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { auth } from '../config/firebaseConfig'; // Firebaseの設定
import { unlink } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const MfaPage = () => {
    const [smsLinked, setSmsLinked] = useState(false);
    const [authAppLinked, setAuthAppLinked] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            user.providerData.forEach(provider => {
                if (provider.providerId === 'phone') {
                    setSmsLinked(true);
                }
                if (provider.providerId === 'totp') { // TOTPは認証アプリを表すプロバイダID
                    setAuthAppLinked(true);
                }
            });
        }
    }, []);

    const handleLinkSms = () => {
        navigate('phone-input')
    };

    const handleUnlinkSms = () => {
        unlink(auth.currentUser, 'phone')
            .then(() => {
                setSmsLinked(false);
            })
            .catch(error => {
                console.error('SMSリンクの解除に失敗しました:', error);
            });
    };

    const handleLinkAuthApp = () => {
        // 認証アプリのリンク処理のロジックを追加
    };

    const handleUnlinkAuthApp = () => {
        unlink(auth.currentUser, 'totp')
            .then(() => {
                setAuthAppLinked(false);
            })
            .catch(error => {
                console.error('認証アプリリンクの解除に失敗しました:', error);
            });
    };

    return (
        <Box
            sx={{
                p: 4,
                maxWidth: '600px',
                margin: '0 auto',
            }}
        >
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', textAlign: 'center' }}>
                二要素認証 (MFA)
            </Typography>

            <Box sx={{ mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                    SMS
                </Typography>
                {smsLinked ? (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleUnlinkSms}
                        fullWidth
                        sx={{ borderRadius: 10, py: 1.5, fontSize: '16px', fontWeight: 'bold' }}
                    >
                         SMSを解除する
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLinkSms}
                        fullWidth
                        sx={{ borderRadius: 10, py: 1.5, fontSize: '16px', fontWeight: 'bold' }}
                    >
                        SMSを有効化する
                    </Button>
                )}
            </Box>

            <Box>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                    認証アプリ
                </Typography>
                {authAppLinked ? (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleUnlinkAuthApp}
                        fullWidth
                        sx={{ borderRadius: 10, py: 1.5, fontSize: '16px', fontWeight: 'bold' }}
                    >
                        認証アプリを解除する
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLinkAuthApp}
                        fullWidth
                        sx={{ borderRadius: 10, py: 1.5, fontSize: '16px', fontWeight: 'bold' }}
                    >
                      認証アプリを有効化する
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default MfaPage;