import React, { useState } from 'react';
import { Container, Typography, Button, Box, Alert } from '@mui/material';
import { auth } from '../config/firebaseConfig'; // Firebaseの設定をインポート
import { deleteUser } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function AccountDelete() {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    setLoading(true);
    setError('');
    const user = auth.currentUser;

    if (user) {
      try {
        await deleteUser(user);
        navigate('/');
      } catch (error) {
        setError('アカウントの削除に失敗しました。');
      }
    } else {
      setError('ユーザーが認証されていません。');
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 40,
            pb: 5,
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          アカウント削除
        </Typography>
        {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
        <Typography
          sx={{
            fontSize: 20,
            mb: 4,
            textAlign: 'center',
          }}
          variant="body1"
          gutterBottom
        >
          アカウントを削除すると、すべてのデータが失われます。この操作は元に戻せません。本当に削除しますか？
        </Typography>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteAccount}
          disabled={loading}
          sx={{
            fontWeight: 600,
            fontSize: 25,
            mt: 2,
            borderRadius: 10,
            px: 10,
            height: 48,
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...(loading) && {
              color: 'gray',
              backgroundColor: 'lightgray',
            },
          }}
        >
          {loading ? '処理中...' : '削除する'}
        </Button>
      </Box>
    </Container>
  );
}

export default AccountDelete;