import React from 'react';
import { Box, Typography, List, Divider } from '@mui/material';
import { useAppContext } from '../contexts/AppContext';
import MemberDataCell from './MemberDataCell'; // MemberDataCellをインポート

function MemberList() {
    const { members, waitingMembers, userData } = useAppContext();

    return (
        <Box
            sx={{
                px: '10%',
                height: '100vh', // ビューポート全体の高さ
                overflowY: 'auto', // スクロールを可能にする
            }}
        >
            <Typography variant="h4" gutterBottom>メンバー</Typography>
            {userData.isAdmin && (
                <>
                    <Typography variant="h6" gutterBottom>承認待ちのメンバー ({waitingMembers.length}人)</Typography>
                    <List>
                        {waitingMembers.map((member) => (
                            <Box key={member.id} sx={{ mb: 2 }}>
                                <MemberDataCell
                                    member={member}
                                    memberStatus="waitingMember" // 承認待ちのステータス
                                />
                            </Box>
                        ))}
                    </List>
                    {waitingMembers.length === 0 && (
                        <Typography>承認待ちのメンバーはいません</Typography>
                    )}
                </>
            )}
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>参加済メンバー ({members.length}人)</Typography>
            <List>
                {members.map((member) => (
                    <Box key={member.id} sx={{ mb: 2 }}>
                        <MemberDataCell
                            member={member}
                            memberStatus="activeMember" // 参加済みのステータス
                        />
                    </Box>
                ))}
            </List>
            {members.length === 0 && (
                <Typography>メンバーはいません</Typography>
            )}
        </Box>
    );
}

export default MemberList;