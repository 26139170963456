import React, { useState } from 'react';
import { Box, Typography, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import { auth } from '../config/firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useAppContext } from '../contexts/AppContext';

const PasswordReset = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { userData } = useAppContext();

    const handlePasswordReset = async () => {
        setLoading(true);
        setMessage('');
        setError('');
        try {
            await sendPasswordResetEmail(auth, userData.email);
            setMessage('パスワードリセットのメールを送信しました。');
        } catch (error) {
            setError('エラーが発生しました。もう一度お試しください。');
            console.error('Password reset error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setMessage('');
        setError('');
    };

    return (
        <Box
            sx={{
                p: 4,
                maxWidth: '600px',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
                パスワードを変更
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
                パスワードをリセットするためのURLをメールに送信します。下の送信ボタンを押してください。
            </Typography>

            <Button
                variant="contained"
                onClick={handlePasswordReset}
                disabled={loading}
                fullWidth
                sx={{
                    fontWeight: 700,
                    fontSize: '16px',
                    textTransform: 'none',
                    borderRadius: '50px', // ボタンを丸くする
                    py: 1.5,
                    bgcolor: loading ? 'grey.500' : 'primary.main',
                    color: '#fff',
                    ':hover': {
                        bgcolor: loading ? 'grey.500' : 'primary.dark',
                    },
                    position: 'relative', // スナックバー位置調整の基準となる
                }}
            >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'パスワードリセットメールを送信'}
            </Button>

            <Snackbar
                open={!!message || !!error}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // ボタンの上に表示
                sx={{
                    position: 'absolute', // ボタンの上にスナックバーを配置
                    top: '-80px', // ボタンの上に移動させる
                }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={error ? 'error' : 'success'}
                    sx={{ width: '100%', fontWeight: 600 }}
                >
                    {error || message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PasswordReset;