import React, { useState } from 'react';
import { Box, Typography, List, ListItem, Divider, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EventDetail from './EventDetail';
import { useAppContext } from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';

function Event() {
  const { events, isMobile ,version} = useAppContext();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const formatDate = (date) => {
    const options = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('ja-JP', options).replace(/\//g, '/');
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    if (isMobile) {
      setIsModalOpen(true); // モーダルを開く
    }
  };

  const handleAddEventClick = () => {
    navigate('/events/add-event');
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  if (isMobile) {
    // モバイル表示: リストのみ表示し、選択したらモーダルで詳細を表示
    return (
      <Box display="flex" flexDirection="column" height="100vh" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            width: '100%',
            position: 'relative',
            overflowY: 'auto', // スクロールを許可
            height: '100vh',
          }}
          p={2}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography
              sx={{
                fontWeight: 600,
              }}
              variant="h6"
              component="h3"
              gutterBottom
            >
              イベント一覧
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEventClick}
              sx={{
                ml: 2,
                height: '36px',
                borderRadius: '10px',
                fontWeight: 'bold',
              }}
            >
              追加
            </Button>
          </Box>
          <Box mt={2} width="100%">
            <List>
              {events.map((event) => (
                <Box
                  key={event.id}
                  sx={{
                    mb: 2,
                    borderRadius: 3,
                    background: '#f5f5f5',
                  }}
                >
                  <ListItem button onClick={() => handleEventClick(event)}>
                    <Box flexDirection="column">
                      <Typography
                        sx={{
                          fontSize: 22,
                          fontWeight: 600,
                          py: 1,
                        }}
                      >
                        {`${formatDate(event.startDate)}`}
                      </Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {`${event.title}`}
                      </Typography>
                    </Box>
                  </ListItem>
                  <Divider />
                </Box>
              ))}
            </List>
          </Box>
        </Box>

        {/* モーダル表示 */}
        <Dialog open={isModalOpen} onClose={handleModalClose} fullWidth maxWidth="md">
          <DialogTitle>
            イベント詳細
            <IconButton
              aria-label="close"
              onClick={handleModalClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {selectedEvent && (
              <EventDetail selectedEvent={selectedEvent} formatDate={formatDate} />
            )}
          </DialogContent>
        </Dialog>
      </Box>
    );
  }

  // デスクトップ表示: リストと詳細を同時に表示
  return (
    <Box display="flex" flexDirection="row" height="100%" width="100%">
      {/* 左サイドのイベントリスト */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          width: 400,
          position: 'relative',
          overflowY: 'auto', // スクロールを許可
          height: '100vh', // 画面全体の高さを確保
        }}
        p={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Typography
            sx={{
              fontWeight: 600,
            }}
            variant="h6"
            component="h3"
            gutterBottom
          >
            イベント一覧
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddEventClick}
            sx={{
              ml: 2,
              height: '36px',
              borderRadius: '10px',
              fontWeight: 'bold',
            }}
          >
            追加
          </Button>
        </Box>
        <Box mt={2} width="100%">
          <List>
            {events.map((event) => (
              <Box
                key={event.id}
                sx={{
                  mb: 2,
                  borderRadius: 3,
                  background: '#f5f5f5',
                }}
              >
                <ListItem button onClick={() => handleEventClick(event)}>
                  <Box flexDirection="column">
                    <Typography
                      sx={{
                        fontSize: 22,
                        fontWeight: 600,
                        py: 1,
                      }}
                    >
                      {`${formatDate(event.startDate)}`}
                    </Typography>
                    <Typography sx={{ fontWeight: 600 }}>
                      {`${event.title}`}
                    </Typography>
                  </Box>
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </Box>
      </Box>

      {/* 右サイドのイベント詳細 */}

      <Typography
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
            fontSize: 12,
            color: '#888',
          }}
        >
          v {version}
        </Typography>


      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="80%"
        p={2}
        sx={{ overflowY: 'auto', height: '100vh' }} // 高さを100vhにし、スクロールを許可
      >
        {selectedEvent ? (
          <Box width="80%" p={2}>
            <EventDetail selectedEvent={selectedEvent} formatDate={formatDate} />
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Typography variant="h4" component="h1" gutterBottom>
              イベントを選択してください
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Event;