import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { auth, db } from '../config/firebaseConfig';
import { collection, query, orderBy, getDocs, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [members, setMembers] = useState([]);
    const [waitingMembers, setWaitingMembers] = useState([]);
    const [events, setEvents] = useState([]);
    const [places, setPlaces] = useState([]); // 新しく追加する場所のリスト
    const [loading, setLoading] = useState(true);
    const [readyForUse, setReadyForUse] = useState(false);
    const [userData, setUserData] = useState({
        uid: '',
        email: '',
        name: '',
        isEmailVerified: false,
        isMember: false,
        isAdmin: false
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [currentOS, setCurrentOS] = useState("iOS");

    const version = "1.0.2"; // 直接ビルド番号を指定

    const getOS = () => {
        const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
        if (/android/i.test(userAgent)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
        return "unknown";
    };

    useEffect(() => {
        setCurrentOS(getOS());
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize); // 画面サイズ変更時の処理を追加

        return () => {
            window.removeEventListener('resize', handleResize); // クリーンアップ
        };
    }, []);

    const fetchMembers = useCallback(() => {
        const isAdmin = userData.isAdmin;
        const memberListRef = doc(db, 'AdminData', 'memberList');

        onSnapshot(memberListRef, async (document) => {
            if (document.exists()) {
                const memberIDs = document.data().memberIDs || [];
                const members = await fetchMemberDetails(memberIDs);
                setMembers(members);

                if (isAdmin) {
                    const waitList = document.data().waitList || [];
                    const waitingMembers = await fetchWaitingMemberDetails(waitList);
                    setWaitingMembers(waitingMembers);
                }
            }
        });
    }, [userData.isAdmin]);

    const fetchMemberDetails = async (memberIDs) => {
        const fetchedMembers = [];
        for (const memberID of memberIDs) {
            const memberDoc = await getDoc(doc(db, 'Users', memberID));
            if (memberDoc.exists()) {
                const { name } = memberDoc.data();
                fetchedMembers.push({ id: memberID, name });
            }
        }
        return fetchedMembers;
    };

    const fetchWaitingMemberDetails = async (memberIDs) => {
        const fetchedMembers = [];
        for (const memberID of memberIDs) {
            const memberDoc = await getDoc(doc(db, 'Users', memberID));
            if (memberDoc.exists()) {
                const { name } = memberDoc.data();
                fetchedMembers.push({ id: memberID, name });
            } 
        }
        return fetchedMembers;
    };

    const fetchEvents = useCallback(async () => {
        try {
            const eventQuery = query(
                collection(db, 'Events'),
                orderBy("startDate"),
            );

            const eventSnapshot = await getDocs(eventQuery);
            const eventList = eventSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    title: data.title,
                    startDate: data.startDate.toDate(),
                    finishDate: data.finishDate.toDate(),
                    body: data.body,
                    placeID: data.placeID,
                    links: data.links,
                    memberIDs: data.memberIDs,
                    senderID: data.senderID
                };
            });
            setEvents(eventList);
        } catch (error) {
            // エラーをキャッチして何もしない
        }
    }, []);

    const fetchPlaces = useCallback(async () => {
        try {
            const placesQuery = query(
                collection(db, 'Places'),
                orderBy("name"),
            );

            const placeSnapshot = await getDocs(placesQuery);
            const placeList = placeSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: data.id,
                    name: data.name,
                    postalCode: data.postalCode,
                    address: data.address
                };
            });
            setPlaces(placeList);
        } catch (error) {
            
        }
    }, []);

    const fetchUserClaims = useCallback(async () => {
        try {
            const user = auth.currentUser;
            if (!user) {
                return false;  // ユーザーが存在しない場合、falseを返す
            }

            const idTokenResult = await user.getIdTokenResult();
            const claims = idTokenResult.claims;
            const isMember = claims.member || claims.tester;
            setUserData(prevState => ({
                ...prevState,
                isMember: isMember || false,
                isAdmin: claims.admin || false,
            }));

            return { isMember: isMember || false, isAdmin: claims.admin || false };  // クレームの取得結果を返す
        } catch (error) {
            return false;  // エラーが発生した場合、falseを返す
        }
    }, []);

    const checkUserState = useCallback(async (user) => {
        if (!user.email) {
            setLoading(false);
            return;
        }

        const email = user.email;
        const uid = user.uid;
        const emailIsVerified = user.emailVerified;
        setUserData(prevState => ({
            ...prevState,
            uid,
            email,
            emailIsVerified
        }));

        if (!user.emailVerified) {
            setLoading(false);
            return;
        }

        try {
            const userDoc = await getDoc(doc(db, 'Users', uid));
            if (!userDoc.exists()) {
                setLoading(false);
                return;
            }

            const data = userDoc.data();
            const name = data?.name || '';
            setUserData(prevState => ({
                ...prevState,
                name: name,
            }));

            const claims = await fetchUserClaims();

            if (!claims.isMember) {
                setLoading(false);
                return;
            }

            if (!readyForUse) { // readyForUse をチェックして、初回のみ実行
                await fetchEvents();
                await fetchMembers();
                await fetchPlaces(); // 場所の取得を追加
                setReadyForUse(true);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, [fetchUserClaims, fetchEvents, fetchMembers, fetchPlaces, readyForUse]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                await checkUserState(user);
            } else {
                setLoading(false);  // ユーザーが認証されていない場合
            }
        });

        return () => unsubscribe();  // クリーンアップ
    }, [checkUserState]);

    return (
        <AppContext.Provider value={{
            members,
            waitingMembers,
            events,
            places,  // 場所のリストをコンテキストに追加
            loading,
            readyForUse,
            userData,
            isMobile,
            currentOS,
            setCurrentOS,
            setIsMobile,
            setUserData,
            fetchEvents,
            fetchMembers,
            fetchPlaces, // 場所の取得関数をコンテキストに追加
            fetchUserClaims,
            version
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);