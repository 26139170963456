import React, { useState } from 'react';
import { Box, Typography, Button, TextField, CircularProgress } from '@mui/material';

const VerificationCodeInput = ({ confirmationResult }) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);

        confirmationResult.confirm(verificationCode)
            .then((result) => {
                const user = result.user;
                console.log("ユーザーが成功裏に認証されました:", user);
                setLoading(false);
                // 認証成功後の処理（リダイレクトやステート更新など）
            })
            .catch((error) => {
                console.error("認証コード確認エラー:", error);
                setLoading(false);
            });
    };

    return (
        <Box
            sx={{
                p: 4,
                maxWidth: '600px',
                margin: '0 auto',
            }}
        >
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', textAlign: 'center' }}>
                認証コード入力
            </Typography>
            <TextField
                label="認証コード"
                variant="outlined"
                fullWidth
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                sx={{ mb: 4 }}
                autoComplete="one-time-code" // 認証コードの自動入力を有効にする属性
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                sx={{ borderRadius: 10, py: 1.5, fontSize: '16px', fontWeight: 'bold' }}
                disabled={loading || !verificationCode}
            >
                {loading ? <CircularProgress size={24} /> : '認証する'}
            </Button>
        </Box>
    );
};

export default VerificationCodeInput;