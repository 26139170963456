import React, { useState } from 'react';
import { Box, Typography, TextField, Button, CircularProgress, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // 閉じるアイコンをインポート
import { v4 as uuidv4 } from 'uuid';
import {  setDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';

const AddPlaceModal = ({ isOpen, onClose }) => {
    const [newPlace, setNewPlace] = useState({ name: '', address: '', postalCode: '' });
    const [isAddingPlace, setIsAddingPlace] = useState(false);

    const handleNewPlaceChange = (e) => {
        const { name, value } = e.target;
        setNewPlace((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddPlace = async () => {
        setIsAddingPlace(true);
        const newPlaceId = uuidv4(); // UUIDを生成
        try {
            const docData = {
                ...newPlace,
                id: newPlaceId // idフィールドにUUIDを追加
            };
            await setDoc(doc(db, 'Places', newPlaceId), docData); // UUIDをdocumentIDに指定
            onClose(); // モーダルを閉じる
            setNewPlace({ name: '', address: '', postalCode: '' }); // フォームをリセット
        } catch (error) {
            console.error('Error adding place: ', error);
        } finally {
            setIsAddingPlace(false);
        }
    };

    // 全てのフォームが入力されているかチェック
    const isFormValid = newPlace.name && newPlace.address && newPlace.postalCode;

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                }}
            >
                {/* 閉じるボタンを右上に配置 */}
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Typography variant="h5" sx={{mb:2}} fontWeight="bold">新しい場所を追加</Typography>
                <TextField
                    label="場所の名前"
                    name="name"
                    value={newPlace.name}
                    onChange={handleNewPlaceChange}
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="住所"
                    name="address"
                    value={newPlace.address}
                    onChange={handleNewPlaceChange}
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="郵便番号(ハイフンなし)"
                    name="postalCode"
                    value={newPlace.postalCode}
                    onChange={handleNewPlaceChange}
                    fullWidth
                    sx={{ mb: 3 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddPlace}
                    disabled={!isFormValid || isAddingPlace} // 全てのフィールドが入力されていないとボタンを無効化
                    fullWidth
                    sx={{ borderRadius: 10, py: 1.5, fontSize: '16px', fontWeight: 'bold' }}
                >
                    {isAddingPlace ? <CircularProgress size={24} color="inherit" /> : '場所を追加する'}
                </Button>
            </Box>
        </Modal>
    );
};

export default AddPlaceModal;