import { Box, Grid, Typography } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../config/firebaseConfig';
import { useAppContext } from '../contexts/AppContext';

function EventDetail({ selectedEvent }) {
   const { isMobile } = useAppContext();
   const [placeName, setPlaceName] = useState('');
   const [placeAddress, setPlaceAddress] = useState('');
   const [placePostalCode, setPlacePostalCode] = useState('');

   useEffect(() => {
      const fetchPlaceDetails = async () => {
         if (selectedEvent && selectedEvent.placeID) {
            try {
               // まずローカルストレージから場所データを取得
               const cachedPlaceData = localStorage.getItem(`place_${selectedEvent.placeID}`);

               if (cachedPlaceData) {
                  try {
                     // ローカルストレージにデータがある場合、それを使用
                     const { name, address, postalCode } = JSON.parse(cachedPlaceData);
                     setPlaceName(name);
                     setPlaceAddress(address);

                     const formattedPostalCode = formatPostalCode(postalCode);
                     setPlacePostalCode(formattedPostalCode);
                  } catch (error) {
                     console.error('JSON parse error:', error);
                     localStorage.removeItem(`place_${selectedEvent.placeID}`); // 壊れたデータを削除
                  }
               } else {
                  // ローカルストレージにデータがない場合、Firestoreから取得
                  const placeDoc = await getDoc(doc(db, 'Places', selectedEvent.placeID));
                  if (placeDoc.exists()) {
                     const placeData = placeDoc.data();
                     const { name, address, postalCode } = placeData;
                     setPlaceName(name);
                     setPlaceAddress(address);

                     const formattedPostalCode = formatPostalCode(postalCode);
                     setPlacePostalCode(formattedPostalCode);
                     localStorage.setItem(`place_${selectedEvent.placeID}`, JSON.stringify({ name, address, postalCode }));
                  } else {
                     setPlaceName('不明な場所');
                     setPlaceAddress('');
                     setPlacePostalCode('');
                  }
               }
            } catch (error) {
               console.error('Error fetching place details:', error);
            }
         }
      };

      fetchPlaceDetails();
   }, [selectedEvent]);

   const getDay = (date) => {
      const weekday = date.getDay(); // 0 (日曜日) から 6 (土曜日) の数値を返す
      switch (weekday) {
         case 0:
            return { dayData: 'Sunday', dayLabel: '日' };
         case 1:
            return { dayData: 'Monday', dayLabel: '月' };
         case 2:
            return { dayData: 'Tuesday', dayLabel: '火' };
         case 3:
            return { dayData: 'Wednesday', dayLabel: '水' };
         case 4:
            return { dayData: 'Thursday', dayLabel: '木' };
         case 5:
            return { dayData: 'Friday', dayLabel: '金' };
         case 6:
            return { dayData: 'Saturday', dayLabel: '土' };
         default:
            return { dayData: 'Error', dayLabel: 'エラー' };
      }
   };

   function formatPostalCode(postalCode) {
      // 郵便番号が7文字かどうかを確認
      if (postalCode.length === 7) {
         return `〒${postalCode.slice(0, 3)}-${postalCode.slice(3)}`;
      } else {
         // 郵便番号が7文字でない場合はそのまま返す
         return postalCode;
      }
   }

   const formatDate = (date) => {
      const options = { month: 'numeric', day: 'numeric' };
      const timeOptions = { hour: 'numeric', minute: 'numeric' };
      const formattedDate = date.toLocaleDateString('ja-JP', options).replace(/\//g, '/');
      const formattedTime = date.toLocaleTimeString('ja-JP', timeOptions);
      const { dayData, dayLabel } = getDay(date);
      return {
         formattedDate,
         dayData,
         dayLabel,
         formattedTime
      };
   };

   const { formattedDate: startDateFormatted, dayData: startDayData, dayLabel: startDayLabel, formattedTime: startTimeFormatted } = formatDate(selectedEvent.startDate);
   const { formattedDate: endDateFormatted, dayData: endDayData, dayLabel: endDayLabel, formattedTime: endTimeFormatted } = formatDate(selectedEvent.finishDate);

   const isRestDay = (dayData) => dayData === 'Sunday' || dayData === 'Saturday';


   return (
      <Box
         display="flex"
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="flex-start"
         width="100%"
         p={2}
         sx={{ overflow: 'hidden' }}
      >
         {startDateFormatted === endDateFormatted ?
            <Box>
               <Typography
                  sx={{
                     fontSize: 23,
                     color: isRestDay(startDayData) ? 'white' : 'black',
                     backgroundColor: startDayData === 'Sunday' ? 'red' : startDayData === 'Saturday' ? 'blue' : 'transparent',
                     padding: '6px',
                     borderRadius: '10px',
                     display: 'inline-block'
                  }}
                  variant="body1"
                  gutterBottom>
                  {`${startDateFormatted} (${startDayLabel})`}
               </Typography>
               <Typography
                  sx={{
                     fontSize: 23,
                     padding: '6px',
                     borderRadius: '10px',
                     display: 'inline-block'
                  }}
                  variant="body1"
                  gutterBottom>
                  {`${startTimeFormatted} 〜 ${endTimeFormatted}`}
               </Typography>
            </Box> :
            isMobile ? (
               <Box>
                  <Grid container direction="column" >
                     <Grid item>
                        <Grid container spacing={1}>
                           <Grid item>
                              <Typography
                                 sx={{
                                    fontSize: 23,
                                    color: isRestDay(startDayData) ? 'white' : 'black',
                                    backgroundColor: startDayData === 'Sunday' ? 'red' : startDayData === 'Saturday' ? 'blue' : 'transparent',
                                    padding: '6px',
                                    borderRadius: '10px',
                                    display: 'inline-block'
                                 }}
                                 variant="body1"
                                 gutterBottom>
                                 {`${startDateFormatted} (${startDayLabel})`}
                              </Typography>
                           </Grid>
                           <Grid item>
                              <Typography
                                 sx={{
                                    fontSize: 23,
                                    padding: '6px',
                                    borderRadius: '10px',
                                    display: 'inline-block'
                                 }}
                                 variant="body1"
                                 gutterBottom>
                                 {`${startTimeFormatted}`}
                              </Typography>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item>
                        <Grid container spacing={1}>
                           <Grid item>
                              <Typography
                                 sx={{
                                    fontSize: 23,
                                    color: isRestDay(endDayData) ? 'white' : 'black',
                                    backgroundColor: endDayData === 'Sunday' ? 'red' : endDayData === 'Saturday' ? 'blue' : 'transparent',
                                    padding: '6px',
                                    borderRadius: '10px',
                                    display: 'inline-block'
                                 }}
                                 variant="body1"
                                 gutterBottom>
                                 {`${endDateFormatted} (${endDayLabel})`}
                              </Typography>
                           </Grid>
                           <Grid item>
                              <Typography
                                 sx={{
                                    fontSize: 23,
                                    padding: '6px',
                                    borderRadius: '10px',
                                    display: 'inline-block'
                                 }}
                                 variant="body1"
                                 gutterBottom>
                                 {`${endTimeFormatted}`}
                              </Typography>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Box>
            ) : (
               <Box >
                  <Typography
                     sx={{
                        fontSize: 23,
                        color: isRestDay(startDayData) ? 'white' : 'black',
                        backgroundColor: startDayData === 'Sunday' ? 'red' : startDayData === 'Saturday' ? 'blue' : 'transparent',
                        padding: '6px',
                        borderRadius: '10px',
                        display: 'inline-block'
                     }}
                     variant="body1"
                     gutterBottom>
                     {`${startDateFormatted} (${startDayLabel})`}
                  </Typography>
                  <Typography
                     sx={{
                        fontSize: 23,
                        padding: '6px',
                        borderRadius: '10px',
                        display: 'inline-block'
                     }}
                     variant="body1"
                     gutterBottom>
                     {`${startTimeFormatted}`}
                  </Typography>
                  <Typography
                     sx={{
                        fontSize: 23,
                        padding: '6px',
                        borderRadius: '10px',
                        display: 'inline-block'
                     }}
                     variant="body1"
                     gutterBottom>
                     {' ~ '}
                  </Typography>
                  <Typography
                     sx={{
                        fontSize: 23,
                        color: isRestDay(endDayData) ? 'white' : 'black',
                        backgroundColor: endDayData === 'Sunday' ? 'red' : endDayData === 'Saturday' ? 'blue' : 'transparent',
                        padding: '6px',
                        borderRadius: '10px',
                        display: 'inline-block'
                     }}
                     variant="body1"
                     gutterBottom>
                     {`${endDateFormatted} (${endDayLabel})`}
                  </Typography>
                  <Typography
                     sx={{
                        fontSize: 23,
                        padding: '6px',
                        borderRadius: '10px',
                        display: 'inline-block'
                     }}
                     variant="body1"
                     gutterBottom>
                     {`${endTimeFormatted}`}
                  </Typography>
               </Box>
            )}


         <Typography
            sx={{
               fontSize: 26,
               fontWeight: 600
            }}
            variant="h1"
            gutterBottom>
            {selectedEvent.title}
         </Typography>


         <Typography
            sx={{
               fontSize: 20
            }}
            variant="body1"
            gutterBottom>
            {selectedEvent.body}
         </Typography>

         <Typography
            sx={{
               mt: 5,
               fontWeight: 600
            }}
            variant="h5" gutterBottom>
            場所
         </Typography>
         <Box
            sx={{
               padding: 2,
               backgroundColor: '#f5f5f5',
               borderRadius: 5
            }}
         >
            <Typography
               sx={{
                  fontWeight: 600
               }}
               variant="h6" gutterBottom>
               {placeName}
            </Typography>
            <Typography
               sx={{
                  fontWeight: 600
               }}
               variant="body1" gutterBottom>
               {placePostalCode}
            </Typography>
            <Typography
               sx={{
                  fontWeight: 600
               }}
               variant="body1" gutterBottom>
               {placeAddress}
            </Typography>
         </Box>
      </Box>
   );
}

export default EventDetail;