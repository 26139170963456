import React, { useState } from 'react';
import { Box, TextField, Button, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import { db } from '../config/firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';
import { useAppContext } from '../contexts/AppContext';

const ProfileEdit = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { userData, setUserData } = useAppContext();
    const [name, setName] = useState(userData.name);

    const handleSave = async () => {
        setLoading(true);
        setError('');
        setSuccess('');
        try {
            const userDocRef = doc(db, 'Users', userData.uid);
            await updateDoc(userDocRef, { name });
            setUserData(prevState => ({
                ...prevState,
                name,
            }));
            setSuccess('プロフィールが更新されました。');
        } catch (error) {
            setError('エラーが発生しました。もう一度お試しください。');
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setError('');
        setSuccess('');
    };

    return (
        <Box
            sx={{
                p: 4,
                maxWidth: '600px',
                margin: '0 auto',
            }}
        >
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', textAlign: 'center' }}>
                プロフィール編集
            </Typography>

            <TextField
                label="名前"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                sx={{
                    mb: 4,
                    fontWeight: 600,
                }}
                disabled={loading}
            />

            <Button
                variant="contained"
                onClick={handleSave}
                disabled={loading}
                fullWidth
                sx={{
                    borderRadius: 10,
                    py: 1.5,
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    bgcolor: loading ? 'grey.500' : 'primary.main',
                    color: 'white',
                    ':hover': {
                        bgcolor: loading ? 'grey.500' : 'primary.dark',
                    },
                }}
            >
                {loading ? <CircularProgress size={24} color="inherit" /> : '保存'}
            </Button>

            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', fontWeight: 600 }}>
                    {error}
                </Alert>
            </Snackbar>

           
            <Snackbar
                open={!!success}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // ボタンの上に表示
                sx={{
                    position: 'absolute', // ボタンの上にスナックバーを配置
                    top: '-80px', // ボタンの上に移動させる
                }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%', fontWeight: 600 }}>
                    {success}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProfileEdit;