import React, {  useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from './contexts/AppContext';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const RootPage = () => {
    const { loading, readyForUse } = useAppContext()
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading) {
            if (readyForUse) {
                navigate('/events');
            } else {
                navigate('/home');
            }
        }
    }, [loading, readyForUse, navigate]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return null;
};

export default RootPage;