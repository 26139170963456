import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import { auth } from '../config/firebaseConfig';
import { linkWithPopup, unlink } from 'firebase/auth';
import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth';

const AccountLink = () => {
    const [googleLinked, setGoogleLinked] = useState(false);
    const [appleLinked, setAppleLinked] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const [appleLoading, setAppleLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            user.providerData.forEach(provider => {
                if (provider.providerId === 'google.com') {
                    setGoogleLinked(true);
                }
                if (provider.providerId === 'apple.com') {
                    setAppleLinked(true);
                }
            });
        }
    }, []);

    const handleLinkGoogle = () => {
        setGoogleLoading(true);
        const provider = new GoogleAuthProvider();
        linkWithPopup(auth.currentUser, provider)
            .then(() => {
                setGoogleLinked(true);
            })
            .catch(() => {
                setError('Googleアカウントのリンクに失敗しました');
            })
            .finally(() => {
                setGoogleLoading(false);
            });
    };

    const handleUnlinkGoogle = () => {
        setGoogleLoading(true);
        unlink(auth.currentUser, 'google.com')
            .then(() => {
                setGoogleLinked(false);
            })
            .catch(() => {
                setError('Googleアカウントの解除に失敗しました');
            })
            .finally(() => {
                setGoogleLoading(false);
            });
    };

    const handleLinkApple = () => {
        setAppleLoading(true);
        const provider = new OAuthProvider('apple.com');
        linkWithPopup(auth.currentUser, provider)
            .then(() => {
                setAppleLinked(true);
            })
            .catch(() => {
                setError('Appleアカウントのリンクに失敗しました');
            })
            .finally(() => {
                setAppleLoading(false);
            });
    };

    const handleUnlinkApple = () => {
        setAppleLoading(true);
        unlink(auth.currentUser, 'apple.com')
            .then(() => {
                setAppleLinked(false);
            })
            .catch(() => {
                setError('Appleアカウントの解除に失敗しました');
            })
            .finally(() => {
                setAppleLoading(false);
            });
    };

    const handleCloseSnackbar = () => {
        setError('');
    };

    const isLoading = googleLoading || appleLoading;

    return (
        <Box
            sx={{
                p: 4,
                maxWidth: '600px',
                margin: '0 auto',
            }}
        >
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', textAlign: 'center' }}>
                アカウント連携
            </Typography>

            <Box sx={{ mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                    Googleアカウント
                </Typography>
                {googleLinked ? (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleUnlinkGoogle}
                        fullWidth
                        sx={{
                            borderRadius: 10,
                            py: 1.5,
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            bgcolor: isLoading ? 'grey.500' : 'error.main',
                        }}
                        disabled={isLoading}
                    >
                        {googleLoading ? <CircularProgress size={24} /> : '連携解除する'}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLinkGoogle}
                        fullWidth
                        sx={{
                            borderRadius: 10,
                            py: 1.5,
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            bgcolor: isLoading ? 'grey.500' : 'primary.main',
                        }}
                        disabled={isLoading}
                    >
                        {googleLoading ? <CircularProgress size={24} /> : 'Googleアカウントを連携する'}
                    </Button>
                )}
            </Box>

            <Box>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                    Appleアカウント
                </Typography>
                {appleLinked ? (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleUnlinkApple}
                        fullWidth
                        sx={{
                            borderRadius: 10,
                            py: 1.5,
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            bgcolor: isLoading ? 'grey.500' : 'error.main',
                        }}
                        disabled={isLoading}
                    >
                        {appleLoading ? <CircularProgress size={24} /> : '連携解除する'}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLinkApple}
                        fullWidth
                        sx={{
                            borderRadius: 10,
                            py: 1.5,
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            bgcolor: isLoading ? 'grey.500' : 'primary.main',
                        }}
                        disabled={isLoading}
                    >
                        {appleLoading ? <CircularProgress size={24} /> : 'Appleアカウントを連携する'}
                    </Button>
                )}
            </Box>

            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AccountLink;