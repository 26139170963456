import React, { useEffect, useState } from 'react';
import { Container, Button, Typography, Box, CircularProgress, Alert } from '@mui/material';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../config/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';  // AppContext の正しいパスを指定

function EmailVerification() {
  const [verificationSent, setVerificationSent] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUserData } = useAppContext()

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 60000); // 1分後にエラーメッセージを消去

      return () => clearTimeout(timer); // クリーンアップタイマー
    }
  }, [error]);

  useEffect(() => {
    const sendVerification = async () => {
        try {
          await sendEmailVerification(auth.currentUser);
          setVerificationSent(true);
        } catch (error) {
          setError('認証メールの送信に失敗しました。');
        }
    };
    sendVerification();
  }, []);

  const handleReload = async () => {
    setLoading(true);
    setError('');
    try {
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        setUserData(prevState => ({
          ...prevState,
          isEmailVerified: true,
        }));
        navigate('/name-input');
      } else {
        setError('メール認証が完了していません。');
      }
    } catch (error) {
      setError('ユーザー情報の読み込みに失敗しました。');
    }
    setLoading(false);
  };

  const handleResend = async () => {
    setLoading(true);
    setError('');
    try {
      await sendEmailVerification(auth.currentUser);
      alert('メールを送信しました。');
    } catch (error) {
      setError('認証メールの再送信に失敗しました。');
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography
          sx={{
            fontWeight: 600,
            pb: 5,
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          メール認証
        </Typography>
        {error && <Alert severity="error" sx={{
          borderRadius: 10,
          mb: 2,
          width: '100%',
          mt: 2
        }}>
          {error}
        </Alert>}
        {verificationSent && <Alert severity="success" sx={{
          borderRadius: 10,
          mb: 5,
          width: '100%',
          mt: 2
        }}>
          認証メールを送信しました。メールに記載されているURLにアクセスしてください。
        </Alert>}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          maxWidth="400px"
        >
          <Button
            variant="contained"
            onClick={handleReload}
            disabled={loading}
            sx={{
              fontWeight: 600,
              fontSize: 16,
              mt: 2,
              borderRadius: 10,
              height: 40,
            py:3,
              maxWidth: '400px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...(loading && {
                color: 'gray',
                backgroundColor: 'lightgray',
              }),
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'URLを確認しました'}
          </Button>
          <Button
            variant="text"
            onClick={handleResend}
            disabled={loading}
            sx={{
              fontWeight: 500,
              mt: 2,
              borderRadius: 10,
              px: 5,
              height: 48,
              width: '100%',
              maxWidth: '400px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...(loading && {
                color: 'gray',
                borderColor: 'lightgray',
              }),
            }}
          >
            {loading ? <CircularProgress size={24} /> : '認証メールを再送信'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default EmailVerification;