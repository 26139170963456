import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, MenuItem, Select, CircularProgress } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db, auth } from '../../config/firebaseConfig';
import dayjs from 'dayjs';
import AddPlaceModal from './AddPlaceModal';
const AddEvent = () => {
    const { isMobile } = useAppContext();
    const location = useLocation();
    const navigate = useNavigate();
    const { places } = useAppContext();
    const [selectedPlaceId, setSelectedPlaceId] = useState('');
    const [eventName, setEventName] = useState('');
    const [eventBody, setEventBody] = useState('');
    const [eventStartDate, setEventStartDate] = useState(dayjs());
    const [eventFinishDate, setEventFinishDate] = useState(dayjs());
    const [isLoading, setIsLoading] = useState(false);

    const [errors, setErrors] = useState({
        eventName: '',
        eventBody: '',
        eventStartDate: '',
        eventFinishDate: '',
        selectedPlaceId: ''
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (location.state && location.state.placeId) {
            setSelectedPlaceId(location.state.placeId);
        }
    }, [location.state]);

    const handlePlaceSelect = (event) => {
        setSelectedPlaceId(event.target.value);
    };

    const validateFields = () => {
        const newErrors = {
            eventName: eventName ? '' : 'イベント名を入力してください',
            eventBody: eventBody ? '' : 'イベント詳細を入力してください',
            eventStartDate: eventStartDate ? '' : '開始日時を選択してください',
            eventFinishDate: eventFinishDate ? '' : '終了日時を選択してください',
            selectedPlaceId: selectedPlaceId ? '' : '場所を選択してください'
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const handleClose = () => {
        navigate('/events');
    };

    const handleSubmit = async () => {
        if (validateFields()) {
            setIsLoading(true);
            const selectedPlace = places.find(p => p.id === selectedPlaceId);
            if (selectedPlace) {
                const uid = auth.currentUser?.uid;
                const docData = {
                    startDate: Timestamp.fromDate(eventStartDate.toDate()),
                    finishDate: Timestamp.fromDate(eventFinishDate.toDate()),
                    title: eventName,
                    body: eventBody,
                    placeID: selectedPlace.id,
                    memberIDs: [],
                    senderID: uid,
                };
                try {
                    await addDoc(collection(db, 'Events'), docData);
                    navigate('/events');
                } catch (error) {
                    console.error('Error adding document: ', error);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    };

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                sx={{
                    maxHeight: '80vh', // 高さ制限
                    overflowY: 'auto', // スクロール可能に
                }}
            >
                <Box
                    p={4}
                    sx={{
                        maxWidth: 550,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mx: 'auto',
                    }}
                >
                    <Box
                        p={4}
                        sx={{
                            maxWidth: 550,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                            mx: 'auto',
                        }}
                    >
                        <>
                            <Button onClick={handleClose} sx={{ mx: 2 }}>閉じる</Button>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                イベント追加
                            </Typography>

                        </>
                    </Box>

                    <Box
                        pb={4}
                        sx={{
                            maxWidth: 550,
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row', // isMobileで縦並びに変更
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mx: 'auto',
                        }}
                    >
                        <>
                            <DateTimePicker
                                sx={{
                                    width: isMobile ? '100%' : 200, // モバイルの場合は幅を100%に
                                    mx: 2,
                                    my: 3
                                }}
                                label="開始日時"
                                value={eventStartDate}
                                onChange={(newValue) => setEventStartDate(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{ mb: 4 }}
                                        error={!!errors.eventStartDate}
                                        helperText={errors.eventStartDate}
                                    />
                                )}
                                format="M月D日 H:mm"
                                ampm={false}
                                disablePast={true}
                            />

                            <DateTimePicker
                                sx={{
                                    width: isMobile ? '100%' : 200, // モバイルの場合は幅を100%に
                                    mx: 2
                                }}
                                label="終了日時"
                                value={eventFinishDate}
                                onChange={(newValue) => setEventFinishDate(newValue)}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{ mb: 4 }}
                                        error={!!errors.eventFinishDate}
                                        helperText={errors.eventFinishDate}
                                    />
                                )}
                                format="M月D日 H:mm"
                                ampm={false}
                                disablePast={true}
                                minDate={eventStartDate}
                            />
                        </>
                    </Box>

                    <TextField
                        label="イベント名"
                        variant="outlined"
                        fullWidth
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                        sx={{ mb: 4 }}
                        error={!!errors.eventName}
                        helperText={errors.eventName}
                    />
                    <TextField
                        label="イベント詳細"
                        variant="outlined"
                        fullWidth
                        value={eventBody}
                        onChange={(e) => setEventBody(e.target.value)}
                        multiline
                        rows={4}
                        sx={{ mb: 4 }}
                        error={!!errors.eventBody}
                        helperText={errors.eventBody}
                    />
                    <Box
                        sx={{
                            display: 'flex', // 横並びにする
                            justifyContent: 'space-between', // 左右に配置
                            alignItems: 'center', // 垂直方向で中央揃え
                            mb: 4, // 下部マージン
                            width: '100%', // 親要素の幅を100%にする
                        }}
                    >
                        <Select
                            value={selectedPlaceId}
                            onChange={handlePlaceSelect}
                            fullWidth
                            displayEmpty
                            sx={{ error: !!errors.selectedPlaceId, width: '80%' }} // Selectボックスの幅を調整
                        >
                            <MenuItem value="" disabled>
                                場所を選択
                            </MenuItem>
                            {places.map((place) => (
                                <MenuItem key={place.id} value={place.id}>
                                    {place.name}
                                </MenuItem>
                            ))}
                        </Select>

                        {/* ボタンをSelectの右下に配置 */}
                        <Button
                            color="primary"
                            onClick={handleOpenModal}
                            sx={{
                                fontSize: '16px', // ボタンを小さく
                                padding: '4px 8px', // パディング調整
                                borderRadius: 5, // ボタンの角を少し丸く
                                marginLeft: '8px', // Selectボタンとの間に少しスペース
                                whiteSpace: 'nowrap', // ボタン内のテキストを1行にする
                            }}
                        >
                            場所を追加
                        </Button>
                    </Box>
                    {errors.selectedPlaceId && (
                        <Typography color="error" sx={{ mb: 4 }}>
                            {errors.selectedPlaceId}
                        </Typography>
                    )}
                    {selectedPlaceId && (
                        <Typography variant="body1" sx={{ mb: 4 }}>
                            〒{places.find(p => p.id === selectedPlaceId)?.postalCode} {places.find(p => p.id === selectedPlaceId)?.address}
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        fullWidth
                        sx={{
                            borderRadius: 10,
                            py: 1.5,
                            fontSize: '16px',
                            fontWeight: 'bold',
                            bgcolor: isLoading ? 'grey.500' : 'primary.main',
                            color: 'white',
                        }}
                        disabled={isLoading || !eventName || !eventBody}
                    >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'イベントを追加する'}
                    </Button>
                </Box>
            </Box>

            <AddPlaceModal isOpen={isModalOpen} onClose={handleCloseModal} />
        </LocalizationProvider>
    );
};

export default AddEvent;