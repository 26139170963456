import React, { useState } from 'react';
import { Box, Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";

const PhoneNumberInput = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [, setConfirmationResult] = useState(null);
    const navigate = useNavigate()

    // Firebase認証インスタンスの初期化
    const auth = getAuth();

    // ReCAPTCHA認証の設定
    const setupRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                console.log("Recaptcha resolved");
            }
        }, auth);

        window.recaptchaVerifier.render().then(function(widgetId) {
            window.recaptchaWidgetId = widgetId;
        });
    };

    // 電話番号をフォーマット
    const formatPhoneNumber = (number) => {
        return number.startsWith('0') ? '+81' + number.slice(1) : number;
    };

    // SMSコードを送信する
    const handleSubmit = () => {
        setLoading(true);
        setupRecaptcha();
        const appVerifier = window.recaptchaVerifier;
        const formattedNumber = formatPhoneNumber(phoneNumber);

        signInWithPhoneNumber(auth, formattedNumber, appVerifier)
            .then((confirmationResult) => {
                setConfirmationResult(confirmationResult);
                setLoading(false);
                navigate('/sms-verify')
                console.log("SMS sent successfully");
            })
            .catch((error) => {
                console.error("SMS送信エラー:", error);
                setLoading(false);
            });
    };

    return (
        <Box
            sx={{
                p: 4,
                maxWidth: '600px',
                margin: '0 auto',
            }}
        >
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', textAlign: 'center' }}>
                電話番号入力
            </Typography>
            <TextField
                label="電話番号"
                variant="outlined"
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                sx={{ mb: 4 }}
                autoComplete="tel" // これが自動補完のための属性
                inputMode="tel"
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                sx={{ borderRadius: 10, py: 1.5, fontSize: '16px', fontWeight: 'bold' }}
                disabled={loading || !phoneNumber || phoneNumber.length !== 11}
            >
                {loading ? <CircularProgress size={24} /> : 'コードを送信'}
            </Button>
        </Box>
    );
};

export default PhoneNumberInput;