import React, { useState } from 'react';
import { Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useAppContext } from './contexts/AppContext';

function Header() {
  const { isMobile } = useAppContext();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'イベント', to: '/events' },
    { text: 'メンバー', to: '/memberlist' },
    { text: 'マイページ', to: '/mypage' },
  ];

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', px: isMobile ? 2 : 5, py: 2 }}>
      <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" color="inherit" sx={{
          fontWeight: 700,
          fontSize: isMobile ? 24 : 28
        }}>
          同志社起業部Web
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  onClick={toggleDrawer(false)}
                  sx={{ ml: 1, mt: 1 }}
                >
                  <CloseIcon />
                </IconButton>
                <List>
                  {menuItems.map((item) => (
                    <ListItem button  component={Link} to={item.to} key={item.text}>
                      <ListItemText primary={item.text} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {menuItems.map((item) => (
              <Button
                component={Link}
                to={item.to}
                
                variant="text"
                sx={{
                  fontWeight: 500,
                  px: 1,
                  fontSize:16,
                  borderRadius: 10,
                  color: 'black',
                  ':hover': {
                    backgroundColor: '#dcdcdc',
                  },
                }}
                key={item.text}
              >
                {item.text}
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>
    </Box>
  );
}

export default Header;