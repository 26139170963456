import React, { useState } from 'react';
import { Box, Typography, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert } from '@mui/material';
import { useAppContext } from '../contexts/AppContext';
import { approveUser, rejectUser, exitUser } from '../config/functions';

function MemberDataCell({ member, memberStatus }) {
  const { userData } = useAppContext();
  const isAdmin = userData.isAdmin;
  const restrictedUids = ['1L5IR5HyIqVeoRjDg3IQPOnBNh63', 'IBOYNDatXsObdnZhPqIrCAGHddj2',]; // 指定されたUIDリスト
  const isRestricted = restrictedUids.includes(member.id); // ユーザーが制限されているかどうかをチェック

  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [exiting, setExiting] = useState(false);
  const [showDialogForApprove, setShowDialogForApprove] = useState(false);
  const [showDialogForReject, setShowDialogForReject] = useState(false);
  const [showDialogForExit, setShowDialogForExit] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleApprove = async () => {
    setApproving(true);
    const result = await approveUser(member.id);
    setApproving(false);
    if (result) {
      setErrorMessage(result.message);
      setShowError(true);
    }
  };

  const handleReject = async () => {
    setRejecting(true);
    const result = await rejectUser(member.id);
    setRejecting(false);
    if (result) {
      setErrorMessage(result.message);
      setShowError(true);
    }
  };

  const handleExit = async () => {
    setExiting(true);
    const result = await exitUser(member.id);
    setExiting(false);
    if (result) {
      setErrorMessage(result.message);
      setShowError(true);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" p={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: 2 }}>
      <Box>
        <Typography variant="body1" fontWeight="bold">{member.name}</Typography>
        {isAdmin ? (
          <Typography variant="body2">{member.id}</Typography>
        ) : null
        }
      </Box>
      {isAdmin && member.id !== userData.uid && (
        <Box>
          {(isRestricted) ? (
            <Button
              variant="contained"
              disabled
              sx={{
                backgroundColor: 'grey',
                fontWeight: 600,
                pointerEvents: 'none' // ボタンを完全に無効化
              }}
            >
              削除できません
            </Button>
          ) : memberStatus === 'waitingMember' ? (
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mr: 1,
                  bgcolor: "#4169e1",
                  fontWeight: 600
                }}
                onClick={() => setShowDialogForApprove(true)}
                disabled={approving || rejecting}
              >
                {approving ? <CircularProgress size={24} color="inherit" /> : '承認'}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowDialogForReject(true)}
                disabled={approving || rejecting}
                sx={{
                  bgcolor: "#ff0000",
                  fontWeight: 600
                }}
              >
                {rejecting ? <CircularProgress size={24} color="inherit" /> : '拒否'}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={() => setShowDialogForExit(true)}
              disabled={exiting}
              sx={{
                bgcolor: "#ff0000",
                fontWeight: 600
              }}
            >
              {exiting ? <CircularProgress size={24} color="inherit" /> : '退出'}
            </Button>
          )}
        </Box>
      )}

      {/* 承認ダイアログ */}
      <Dialog open={showDialogForApprove} onClose={() => setShowDialogForApprove(false)}>
        <DialogTitle sx={{ fontWeight: 600 }}>確認</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 600 }}>
            {`${member.name}さんの参加を承認しますか？`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialogForApprove(false)} sx={{ fontWeight: 600 }}>
            閉じる
          </Button>
          <Button onClick={handleApprove} color="primary" sx={{ fontWeight: 600 }}>
            承認
          </Button>
        </DialogActions>
      </Dialog>

      {/* 拒否ダイアログ */}
      <Dialog open={showDialogForReject} onClose={() => setShowDialogForReject(false)}>
        <DialogTitle sx={{ fontWeight: 600 }}>確認</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 600 }}>
            {`${member.name}さんの参加を拒否しますか？`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialogForReject(false)} sx={{ fontWeight: 600 }}>
            閉じる
          </Button>
          <Button onClick={handleReject} color="secondary" sx={{ color: "#ff0000", fontWeight: 600 }}>
            拒否する
          </Button>
        </DialogActions>
      </Dialog>

      {/* 退出ダイアログ */}
      <Dialog open={showDialogForExit} onClose={() => setShowDialogForExit(false)}>
        <DialogTitle sx={{ fontWeight: 600 }}>警告</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 600 }}>
            {`${member.name}さんを退出させますか？`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialogForExit(false)} sx={{ fontWeight: 600 }}>
            閉じる
          </Button>
          <Button onClick={handleExit} color="secondary" sx={{ color: "#ff0000", fontWeight: 600 }}>
            退出させる
          </Button>
        </DialogActions>
      </Dialog>

      {/* エラー表示 */}
      <Snackbar open={showError} autoHideDuration={6000} onClose={() => setShowError(false)}>
        <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%', fontWeight: 600 }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MemberDataCell;