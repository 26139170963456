import React from 'react';
import { Box, Typography } from '@mui/material';

const PrivacyPolicy = () => {

    return (
        <Box sx={{
            height: '100vh', // ビューポート全体の高さ
            overflowY: 'auto', // スクロールを可能にする
        }}>


            <Box sx={{ textAlign: 'center', }}>
                <Typography variant="h5" sx={{ mt: 10, fontWeight: 600,mb: 5, borderRadius: '10px' }}>
                    プライバシーポリシー
                </Typography>
            </Box>

            <Box sx={{px:10 }}>
                    <Typography paragraph>
                        同志社起業部アプリ運営チーム（以下，「当チーム」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
                    </Typography>

                    <Typography variant="h5"  sx={{ fontWeight: 600 }} gutterBottom>第1条（個人情報）</Typography>
                    <Typography paragraph>
                        「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，電話番号，その他の記述等により特定の個人を識別できる情報及び容貌，指紋，にかかるデータなどの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
                    </Typography>

                    <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>第2条（個人情報の収集方法）</Typography>
                    <Typography paragraph>
                        本サービスでは個人情報を収集する際に本ポリシーにしたがって個人情報を取り扱います。
                    </Typography>
                    <Typography>
                        ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当チームの提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
                    </Typography>

                    <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>第3条（個人情報を収集・利用する目的）</Typography>
                    <Typography paragraph>
                        当チームが個人情報を収集・利用する目的は，以下のとおりです。
                    </Typography>
                    <Typography paragraph>・本サービスの提供・運営のため</Typography>
                    <Typography paragraph>・ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</Typography>
                    <Typography paragraph>・利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため</Typography>
                    <Typography paragraph>・ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため</Typography>
                    <Typography paragraph>・上記の利用目的に付随する目的</Typography>

                    <Typography variant="h5"  sx={{ fontWeight: 600 }}gutterBottom>第4条（利用目的の変更）</Typography>
                    <Typography paragraph>
                        1. 当チームは，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
                    </Typography>
                    <Typography paragraph>
                        2. 利用目的の変更を行った場合には，変更後の目的について，当チーム所定の方法により，ユーザーに通知し，または本サービス上に公表するものとします。
                    </Typography>

                    <Typography variant="h5"  sx={{ fontWeight: 600 }}gutterBottom>第5条（個人情報の第三者提供）</Typography>
                    <Typography paragraph>
                        1. 当チームは，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
                    </Typography>
                    <Typography paragraph>・人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき</Typography>
                    <Typography paragraph>・公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき</Typography>
                    <Typography paragraph>・国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</Typography>
                    <Typography paragraph>・予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき</Typography>
                    <Typography paragraph>　　・利用目的に第三者への提供を含むこと</Typography>
                    <Typography paragraph>　　・第三者に提供されるデータの項目</Typography>
                    <Typography paragraph>　　・第三者への提供の手段または方法</Typography>
                    <Typography paragraph>　　・本人の求めに応じて個人情報の第三者への提供を停止すること</Typography>
                    <Typography paragraph>　　・本人の求めを受け付ける方法</Typography>
                    <Typography paragraph>
                        ・前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
                    </Typography>
                    <Typography paragraph>　　・当チームが利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</Typography>
                    <Typography paragraph>　　・第９条で定める業者に提供する場合</Typography>
                    <Typography paragraph>　　・合併その他の事由による事業の承継に伴って個人情報が提供される場合</Typography>
                    <Typography paragraph>　　・個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合</Typography>

                    <Typography variant="h5"  sx={{ fontWeight: 600 }}gutterBottom>第6条（個人情報の開示）</Typography>
                    <Typography paragraph>
                        1. 当チームは，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。
                    </Typography>
                    <Typography paragraph>
                        ・本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
                    </Typography>
                    <Typography paragraph>
                        ・当チームの業務の適正な実施に著しい支障を及ぼすおそれがある場合
                    </Typography>
                    <Typography paragraph>
                        ・その他法令に違反することとなる場合
                    </Typography>
                    <Typography paragraph>
                        2. 前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
                    </Typography>
                    <Typography variant="h5"  sx={{ fontWeight: 600 }}gutterBottom>第7条（個人情報の訂正および削除）</Typography>
                    <Typography paragraph>
                        1. ユーザーは，当チームの保有する自己の個人情報が誤った情報である場合には，当チームが定める手続きにより，当チームに対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
                    </Typography>
                    <Typography paragraph>
                        2. 当チームは，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
                    </Typography>
                    <Typography paragraph>
                        3. 当チームは，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
                    </Typography>
                    <Typography variant="h5"  sx={{ fontWeight: 600 }}gutterBottom>第8条（個人情報の利用停止等）</Typography>
                    <Typography paragraph>
                        1. 当チームは，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
                    </Typography>
                    <Typography paragraph>
                        2. 前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
                    </Typography>
                    <Typography paragraph>
                        3. 当チームは，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
                    </Typography>
                    <Typography paragraph>
                        4. 前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
                    </Typography>
                    <Typography variant="h5"  sx={{ fontWeight: 600 }}gutterBottom>第9条（個人情報及び個人関連情報の管理）</Typography>
                    <Typography paragraph>
                        1. 本サービスは以下に挙げるデータの全てをGoogle LLCに送信します。
                    </Typography>
                    <Typography paragraph>
                        2. 本サービスで収集するデータと目的は以下の通りです。
                    </Typography>
                    <Typography paragraph>メールアドレス・名前（起業部のユーザー管理のため）</Typography>
                    <Typography paragraph>・パフォーマンス情報、診断情報（GoogleAnalyticsでの分析およびGoogle広告の表示のため）</Typography>
                    <Typography paragraph>・アクセスログ、IPアドレス（Google広告の表示および不正なアクセスの検知のため）</Typography>
                    <Typography variant="h5"  sx={{ fontWeight: 600 }}gutterBottom>第10条（セキュリティーについて）</Typography>
                    <Typography paragraph>本サービスで取得した個人情報はGoogle社が運営しているクラウド「Google Cloud」に保管しております。また、セキュリティの設定を日頃から確認するなど、適切かつ合理的なレベルのセキュリティー対策を行なっております。</Typography>
                    <Typography variant="h5"  sx={{ fontWeight: 600 }}gutterBottom>第11条（プライバシーポリシーの変更）</Typography>
                    <Typography paragraph>
                        1. 本ポリシーが変更された際には本サービス（Webサイトおよびアプリ）内の画面でユーザーに通知します。
                    </Typography>
                    <Typography paragraph>
                        2. 当チームが別途定める場合を除いて，変更後のプライバシーポリシーは，本サービスに掲載したときから効力を生じるものとします。
                    </Typography>
                
            </Box>

            <Box sx={{ textAlign: 'center', padding: '20px', color: '#000000', position: 'relative', width: '100%' }}>
                <Typography>&copy; 2024 Million Connect. All Rights Reserved.</Typography>
            </Box>
        </Box>
    );
};

export default PrivacyPolicy;