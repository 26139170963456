import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './firstlaunch/Home';
import Login from './firstlaunch/Login';
import Register from './firstlaunch/Register';
import EmailVerification from './firstlaunch/EmailVerification';
import ApprovalPending from './firstlaunch/ApprovalPending';
import Event from './main/Event';
import MyPage from './mypage/MyPage';
import ProfileEdit from './mypage/ProfileEdit';
import PasswordReset from './mypage/PasswordReset';
import Layout from './Layout';
import EmailVerificationHandler from './firstlaunch/EmailVerificationHandler';
import NameInput from './firstlaunch/NameInput';
import MemberList from './members/MemberList';
import PrivacyPolicy from './info/PrivacyPolicy';
import AccountDelete from './mypage/AccountDelete';
import RootPage from './RootPage';
import { AppProvider } from './contexts/AppContext';
import MfaPage from './mypage/MfaRootPage';
import AccountLink from './mypage/AccountLink';
import PhoneNumberInput from './mypage/SMS/PhoneNumberInput';
import VerificationCodeInput from './mypage/SMS/VerificationCodeInput';
import PlaceList from './main/add-event/PlaceList';
import AddEvent from './main/add-event/AddEvent';
import RulePage from './info/Rule';

function App() {
  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<RootPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/name-input" element={<NameInput />} />
          <Route path="/approval-pending" element={<ApprovalPending />} />
          <Route path="/events" element={<Layout><Event /></Layout>} />
          <Route path="/events/add-event" element={<Layout><AddEvent /></Layout>} />
          <Route path="/events/places" element={<Layout><PlaceList /></Layout>} />

          <Route path="/memberlist" element={<Layout><MemberList /></Layout>} />
          <Route path="/mypage" element={<Layout><MyPage /></Layout>} />
          <Route path="/mypage/profile" element={<Layout><ProfileEdit /></Layout>} />
          <Route path="/mypage/link" element={<Layout><AccountLink /></Layout>} />
          <Route path="/mypage/mfa" element={<Layout><MfaPage /></Layout>} />
          <Route path="/mypage/phone-input" element={<Layout><PhoneNumberInput /></Layout>} />
          <Route path="/sms-verify" element={<Layout><VerificationCodeInput /></Layout>} />
          <Route path="/mypage/password" element={<Layout><PasswordReset /></Layout>} />
          <Route path="/__/auth/action" element={<EmailVerificationHandler />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<RulePage />} />
          <Route path="/account-delete" element={<Layout><AccountDelete /></Layout>} />
        </Routes>
      </Router>
    </AppProvider>
  );
}

export default App;