import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const RulePage = () => {
    const navigate = useNavigate()
    const handleSignUpClick = () => {
        navigate('/privacy-policy');
    }

    return (
        <Box sx={{
            height: '100vh', // ビューポート全体の高さ
            overflowY: 'auto', // スクロールを可能にする
        }}>
            <Box sx={{ textAlign: 'center', }}>
                <Typography variant="h5" sx={{ mt: 10, fontWeight: 600, mb: 5, borderRadius: '10px' }}>
                    利用規約
                </Typography>
            </Box>

            <Box sx={{ px: 10 }}>
                <Typography variant="body1" gutterBottom>
                    この利用規約（以下、「本規約」といいます。）は、同志社起業部アプリ運営チーム（以下、「当チーム」といいます。）がウェブサイトおよびiPhone、iPad、Android端末上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
                </Typography>

                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第1条（適用）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    本規約は、ユーザーと当チームとの間の本サービスの利用に関わる一切の関係に適用されるものとします。
                </Typography>

                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第2条（禁止事項）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・法令または公序良俗に違反する行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・犯罪行為に関連する行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・本サービスの内容等、本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・当チーム、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・当チームのサービスの運営を妨害するおそれのある行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・不正アクセスをし、またはこれを試みる行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・他のユーザーに関する個人情報等を収集または蓄積する行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・不正な目的を持って本サービスを利用する行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・他のユーザーに成りすます行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・当チームのサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・その他、当チームが不適切と判断する行為
                </Typography>

                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第3条（本サービスの提供の停止等）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームは，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・大量のアクセス、地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・コンピュータまたは通信回線等が事故により停止した場合
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・その他、当チームが本サービスの提供が困難と判断した場合
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームは、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
                </Typography>

                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第4条（利用制限および登録抹消）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームは，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・本規約のいずれかの条項に違反した場合
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・登録事項に虚偽の事実があることが判明した場合
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・その他、当チームが本サービスの利用を適当でないと判断した場合
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームは、本条に基づき当チームが行った行為によりユーザーに生じた損害について、一切の責任を負いません。
                </Typography>

                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第5条（保証の否認および免責事項）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームは、本サービスがユーザーに提供するデータ（講義情報など）が最新かつ正確であることを一切保証しません。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームは、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チーム、本サービスに起因してユーザーに生じたあらゆる損害について、一切の責任を負いません。ただし、本サービスに関する当チームとユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームは、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第6条（サービス内容の変更等）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームはユーザーへの告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第7条（利用規約の変更）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームは以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・本規約の変更がユーザーの一般の利益に適合するとき。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ・本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームはユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。
                </Typography>

                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第8条（個人情報の取扱い）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    当チームは、本サービスの利用によって取得する個人情報については、下記プライバシーポリシーに従い適切に取り扱うものとします。
                </Typography>
                <Button onClick={handleSignUpClick}>プライバシーポリシー</Button>

                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第9条（通知または連絡）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ユーザーと当チームとの間の通知または連絡は、当チームの定める方法によって行うものとします。当チームは、ユーザーから、当チームが別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第10条（権利義務の譲渡の禁止）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ユーザーは、当チームの書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
                    第11条（準拠法・裁判管轄）
                </Typography>
                <Typography variant="body1" gutterBottom>
                    本規約の解釈にあたっては、日本法を準拠法とします。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    本サービスに関して紛争が生じた場合には、当チームの本店所在地を管轄する裁判所を専属的合意管轄とします。
                </Typography>
            </Box>
        </Box>
    );
};

export default RulePage;