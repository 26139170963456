import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress, Alert } from '@mui/material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../config/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { useAppContext } from '../contexts/AppContext';
import CryptoJS from 'crypto-js';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const { fetchUserClaims, setUserData, userData } = useAppContext()

  function extractDomain(email) {
    const domainLength = 14; 
    return email.slice(-domainLength);
  }

  // ドメインをハッシュ化する関数
  function hashDomain(domain) {
    const hash = CryptoJS.SHA512(domain);
    return hash.toString(CryptoJS.enc.Hex);
  }

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 60000); // 1分後にエラーメッセージを消去

      return () => clearTimeout(timer); // クリーンアップタイマー
    }
  }, [error]);

  useEffect(() => {
    const isEmailValid = email.includes('@');
    const isPasswordNotEmpty = password.trim() !== '';
    setIsButtonDisabled(!isEmailValid || !isPasswordNotEmpty);
  }, [email, password]);

  const handleLogin = async () => {
    setLoading(true);
    setError('');


    const emailDomain = extractDomain(email);
    const hashedDomain = hashDomain(emailDomain);
    if (hashedDomain !== process.env.REACT_APP_ALLOWED_DOMAIN_HASH) {
      setError('同志社大学のメールアドレスを入力してください');
      setLoading(false);
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;
      const userEmail = userCredential.user.email;
      const verified = userCredential.user.emailVerified;

      setUserData(prevState => ({
        ...prevState,
        uid,
        userEmail,
        isEmailValid: verified,
      }));

      // Firestoreからユーザードキュメントを取得
      const userSnap = await getDoc(doc(db, 'Users', uid));
      if (!userSnap.exists()) {
        console.log('1');
        navigate('/approval-pending');
        return;
      }

      const userDoc = userSnap.data();
      const name = userDoc.name;
      setUserData(prevState => ({
        ...prevState,
        name,
      }));

      if (!verified) {
        console.log('2');
        navigate('/email-verification');
        return;
      }

      const claims = await fetchUserClaims();

      if (!claims.isMember) {
        console.log('3');
        navigate('/approval-pending');
        return;
      }

      const snap = await getDoc(doc(db, 'AdminData', 'memberList'));
      if (!snap.exists()) {
        console.log('4');
        navigate('/approval-pending');
        return;
      }

      const docData = snap.data();
      const memberIdList = docData.memberIDs || [];

      if (!memberIdList.includes(uid)) {
        console.log('5');
        navigate('/approval-pending');
        return;
      }

      // 認証成功後の処理
      navigate('/events');
    } catch (error) {
      if (!auth.user) {
        setError(`エラーが発生しました。もう一度お試しください`);
        return;
      }
      if (!userData.isEmailValid) {
        navigate('/email-verification');
      } else {
        navigate('/approval-pending');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography
          sx={{
            fontWeight: 600,
            pb: 5,
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          ログイン
        </Typography>
        {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
        <TextField
          label="メールアドレス"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
          sx={{ mt: 2 }}
        />
        <TextField
          label="パスワード"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          onClick={handleLogin}
          disabled={isButtonDisabled || loading}
          sx={{
            fontWeight: 600,
            fontSize: 20,
            mt: 2,
            borderRadius: 10,
            height: 48,
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...(isButtonDisabled || loading) && {
              color: 'gray',
              backgroundColor: 'lightgray',
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : 'ログイン'}
        </Button>
      </Box>
    </Container>
  );
}

export default Login;